<!--App-->

<template>
  <div id="app">
    <ProfileHeader />
    <Gallery />
  </div>
</template>

<script>
import ProfileHeader from './components/ProfileHeader.vue';
import Gallery from './components/ImageGallery.vue';

export default {
  name: 'App',
  components: {
    ProfileHeader,
    Gallery,
  },
};
</script>

<style>
/* Импортируйте общий CSS файл */
@import './assets/styles/main.css';
</style>
