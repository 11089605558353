<!--ProfileHeader-->
<template>
  <header>
    <div class="container">
      <div class="profile">
        <div class="profile-logo">
          <img :src="profileImage" alt="Profile Image">
        </div>
        <div class="profile-info">
          <h1 class="profile-user-name">Pelmeni.is</h1>
          <div class="profile-social-icons">
            <a href="https://instagram.com/pelmeni.is" target="_blank" rel="noopener noreferrer">
              <InstagramIcon :size="40" />
            </a>
            <a href="https://wa.me/message/KVWNC7NGEQGIC1" target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon :size="40" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import {WhatsAppIcon, InstagramIcon } from 'vue3-simple-icons'
import profileImage from '@/assets/images/logo.jpg';

export default {
  name: 'ProfileHeader',
  components: {
    WhatsAppIcon,
    InstagramIcon
  },
  data() {
    return {
      profileImage,
    };
  },
};
</script>
