<!--ImageGallery-->

<template>
  <main>
    <div class="container">
      <div class="gallery">
        <GalleryItem
            v-for="(image, index) in images"
            :key="index"
            :image="image"
        />
      </div>
    </div>
  </main>
</template>

<script>
import GalleryItem from './GalleryItem.vue';
import image1 from '@/assets/images/image1.jpg';
import image2 from '@/assets/images/image2.jpg';
import image3 from '@/assets/images/image3.jpg';
import image4 from '@/assets/images/image4.jpg';
import image5 from '@/assets/images/image5.jpg';
import image6 from '@/assets/images/image6.jpg';
import image7 from '@/assets/images/image7.jpg';
import image8 from '@/assets/images/image8.jpg';
import image9 from '@/assets/images/image9.jpg';


// Импортируйте остальные изображения

export default {
  name: 'ImageGallery',
  components: {
    GalleryItem,
  },
  data() {
    return {
      images: [
        {
          src: image1,
          likes: 49,
          comments: 2,
          instagramLink: 'https://www.instagram.com/reel/CgMocWmDGBd',
        },
        {
          src: image2,
          likes: 15,
          comments: 0,
          instagramLink: 'https://www.instagram.com/reel/Cg_1IizjaST',
        },
        {
          src: image3,
          likes: 56,
          comments: 2,
          instagramLink: 'https://www.instagram.com/p/C58i--7oaIF',
        },
        {
          src: image4,
          likes: 49,
          comments: 2,
          instagramLink: 'https://www.instagram.com/p/C5nT_d_AiCU',
        },
        {
          src: image5,
          likes: 15,
          comments: 0,
          instagramLink: 'https://www.instagram.com/reel/C0pFVj4olrJ',
        },
        {
          src: image6,
          likes: 56,
          comments: 2,
          instagramLink: 'https://www.instagram.com/p/CxKlEiaAz03',
        },
        {
          src: image7,
          likes: 49,
          comments: 2,
          instagramLink: 'https://www.instagram.com/p/Cw7yVmAo_wE/',
        },
        {
          src: image8,
          likes: 15,
          comments: 0,
          instagramLink: 'https://www.instagram.com/p/Cwz1zTDIAtX/',
        },
        {
          src: image9,
          likes: 56,
          comments: 2,
          instagramLink: 'https://www.instagram.com/p/Cp2al1ZIbd9/',
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Стили оставляем пустыми, если используем общий CSS */
</style>
