<!--GalleryItem-->

<template>
  <div class="gallery-item" tabindex="0" @click="openInstagram">
    <img :src="image.src" class="gallery-image" alt="">

    <div class="gallery-item-info">
      <ul>
        <li class="gallery-item-likes">
          <span class="visually-hidden">Likes:</span>
          <i class="fas fa-heart" aria-hidden="true"></i> {{ image.likes }}
        </li>
        <li class="gallery-item-comments">
          <span class="visually-hidden">Comments:</span>
          <i class="fas fa-comment" aria-hidden="true"></i> {{ image.comments }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GalleryItem',
  props: {
    image: Object,
  },
  methods: {
    openInstagram() {
      window.open(this.image.instagramLink, '_blank');
    },
  },
};
</script>

<style scoped>
/* Добавьте необходимые стили, либо оставьте пустым, если используете общий CSS */
</style>
